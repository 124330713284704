import { Component, OnInit, ElementRef } from '@angular/core';
import { Utils } from 'src/app/shared/services/utils';
import { Language } from 'src/app/core/models/enums/language';
import { Gender } from 'src/app/core/models/enums/gender';
import { PrintObject } from 'src/app/core/models/enums/print-object';
import { TripTikDetailsDto } from '../../models/trip-tik';
import { StaticConfig } from 'src/app/features/lookups/services/config';
import { ConfigObject } from 'src/app/core/models/enums/config-object';
import * as moment from 'moment';
import { LookupDto } from 'src/app/features/lookups/models/lookup';
import { StringJoinPipe } from 'src/app/shared/pipes/string-join.pipe';
import { AuthService } from 'src/app/core/auth/auth.service';

@Component({
  selector: 'mac-trip-tik-print',
  templateUrl: './trip-tik-print.component.html',
  styleUrls: ['./trip-tik-print.component.scss']
})
export class TripTikPrintComponent {

  dto: TripTikDetailsDto;
  qRCodeUrl: string;
  printObject: PrintObject;
  empty: string;
  drivers: string[];
  vOptions: LookupDto[];
  vUpholsteryType: string[];
  cOwnerInfo: string[];

  _colors: string[] = [];
  _makeModel: string[] = [];
  _options: string[] = [];

  get Language() { return Language; };
  get PrintObject() { return PrintObject; };
  get joinByDash() { return Utils.joinByDash; };

  constructor(private elRef: ElementRef, private stringJoin: StringJoinPipe, public auth: AuthService  ) {
  }

  async print(dto: TripTikDetailsDto, printObject: PrintObject) {
    this.dto = dto;
    this.printObject = printObject;
    this.empty = '';// StaticConfig.getConfigKeyValuePairs(ConfigObject.TripTikPrintEmptyValue)[0].value.toString();

    //console.log(dto.documentTemplateVersion);
    this.vOptions = [];
    if (this.dto.vehicle.options)
      this.dto.vehicle.options.forEach(opt => {
        //this.vOptions.push(StaticConfig.getConfigValue(ConfigObject.VehicleOptions, opt).toString())
        this.vOptions.push({ id: opt, titles: <[]>StaticConfig.getConfigValue(ConfigObject.VehicleOptions, opt/*, this.lang*/) });
      });


    this._colors = this.dto.vehicle.colors ? this.dto.vehicle.colors.map(d => this.stringJoin.transform(d.titles)) : [];
    this._options = this.vOptions ? this.vOptions.map(d => this.stringJoin.transform(d.titles)) : [];


    if (this.dto.vehicle.model) {
      this._makeModel = [];
      this._makeModel.push(this.stringJoin.transform([this.dto.vehicle.model.titles[0], this.dto.vehicle.make.titles[0]]));
      this._makeModel.push(this.stringJoin.transform([this.dto.vehicle.model.titles[1], this.dto.vehicle.make.titles[1]]));

      // console.log(this._makeModel);
    }

    this.drivers = this.dto.customer.drivers.map(d => d.name);
    this.cOwnerInfo = [dto.customer.owner.name, dto.customer.owner.address.item1, dto.customer.owner.address.item2]
      .filter(d => d);

    if (this.dto.vehicle.upholsteryType)
      this.vUpholsteryType = <[]>StaticConfig.getConfigValue(ConfigObject.VehicleUpholsteryTypes, this.dto.vehicle.upholsteryType);



    this.dto.issued = this.dto.issued ? this.dto.issued : new Date();
    this.dto.expiryDate = this.dto.expiryDate; //moment(this.dto.issued).add(1, 'year').subtract(1, 'day').toDate();
    this.qRCodeUrl = Utils.generateQrCodeUrl(this.dto.hashedSerial);  //todo: fill db with hashs

    setTimeout(() => {
      const printHtmlContent = this.elRef.nativeElement.innerHTML;
      Utils.printHtml(printHtmlContent);
    }, 500);
  }
}

// export function joinByDash(arr: string[]) {
//   return [...arr].reverse().join(' - ');
// }

// interface String {
//  // toAddPrefix(prefix: string): string;
//   joinByDash(): String;
// }
// interface Array<T> {
//   joinByDash(): Array<T>;
// }




// Array.prototype.joinByDash = function () {
//   const _self = <string[]>this;
//   return [..._self].reverse().join(' - ');
// };


// const joinByDash = function (arr: string[]) {
//   return [...arr].reverse().join(' - ');
// }